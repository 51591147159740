<template>
    <div class="customs-card__goods">
        <div class="customs-card__title">
            Goods
        </div>

        <div class="customs-goods-table">
            <div class="customs-goods-table__tr">
                <div v-for="column in columns" :key="column.id" class="customs-card__goods-th">
                    <button v-if="column.sortable" class="th-sort" @click="toggleSort(column.id)">
                        <svg-importer v-if="sort.key === column.id && sort.order === 1" :key="`${column.id}_up`" icon-name="icons/table-sort-up" />
                        <svg-importer v-else-if="sort.key === column.id && sort.order === -1" :key="`${column.id}_down`" icon-name="icons/table-sort-down" />
                        <svg-importer v-else-if="sort.key === column.id && sort.order === 0 || sort.key !== column.id" :key="`${column.id}_default`" icon-name="icons/table-sort" />
                    </button>

                    <div>
                        {{ column.name }}
                    </div>
                </div>
            </div>
            <div v-if="isLoading" class="flex flex__justify-center mt-4">
                <app-spinner :size="32" />
            </div>
            <template v-else>
                <div v-for="good in filteredData" :key="good.id" class="customs-goods-table__tr">
                    <div v-for="column in columns" :key="`${good.id}_${column.name}`" class="customs-card__goods-td f-word-break-word">
                        {{ good[column.id] }}
                        <span v-if="column.id === 'weight'">
                            kg
                        </span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import AppSpinner from '~/components/AppSpinner';

export default {
    components: { AppSpinner },
    data() {
        return {
            isLoading: true,
            sort: {
                key: '',
                order: 0,
            },
            columns: [
                { id: 'article_number', name: 'Name', width: '' , sortable: false },
                { id: 'description', name: 'Description', width: '' , sortable: false },
                { id: 'number_of_units', name: 'Units', width: '' , sortable: false },
                { id: 'type_of_unit', name: 'Unit type', width: '' , sortable: false },
                { id: 'hs_code', name: 'HS code', width: '' , sortable: false },
                { id: 'net_weight', name: 'Weight', width: '', sortable: true },
            ],
            data: [],
        };
    },
    computed: {
        filteredData() {
            return [...this.data].sort(this.doSort)
        }
    },
    methods: {
        doSort(a, b) {
            if (!this.sort.order) {
                return 0; 
            }

            if (this.sort.order === 1) {
                return a[this.sort.key] > b[this.sort.key] ? 1 : -1;
            }

            return a[this.sort.key] > b[this.sort.key] ? -1 : 1;
        },
        updateSort(colId) {
            if (colId !== this.sort.key) {
                this.sort.key = colId;
                this.sort.order = 1;

                return;
            }

            if (this.sort.order === 1) {
                this.sort.order = -1;

                return;
            }

            if (this.sort.order === -1) {
                this.sort.order = 0;

                return;
            }

            this.sort.order = 1;
        },
        toggleSort(colId) {
            this.updateSort(colId);
        },
    },
    async created() {
        this.isLoading = true;

        const { data: { data } } = await axios.get(`${this.$apiUrl.customs.items}/${this.$route.params.id}`);
        this.data = data;

        this.isLoading = false;
    },
}
</script>
<style>
.customs-goods-table__tr {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
}

.customs-card__title {
    min-height: 44px;
    display: flex;
    align-items: center;
}

.customs-card__goods-th {
    padding-left: 24px;
    position: relative;
    display: flex;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 22px;
        transform: translateY(-50%);
        background-color: #B1BFCA;
    }

    &:first-child {
        padding-left: 0;
        border-left: none;

        &:before {
            content: none;
        }
    }

    &:first-child {
        flex-grow: 1;
    }
}

.customs-card__goods-tr {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
}

.customs-card__goods-td {
    padding-left: 24px;

    &:first-child {
        padding-left: 0;
    }

    &:nth-child(7) {
        padding-left: 0;
    }
    /* &:first-child {
        flex-grow: 1;
        padding-left: 0;
    } */
}

.customs-goods-table {
    margin-top: 12px;
}

.customs-goods-table__tr:not(:first-child){
    padding-top: 8px;
    padding-bottom: 8px;
}
</style>