import axios from 'axios';

export default {
    methods: {
        async downloadFile(url, fileName) {
            try {
                await axios({
                    url,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');

                    link.href = url;
                    link.setAttribute('download', fileName || 'download')

                    document.body.appendChild(link);
                    
                    link.click();
                });
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        },
    },
}